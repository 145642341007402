<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">

            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-10 col-xl-10">

                    <!-- Pretitle -->
                    <h6 class="text-uppercase text-muted">Submit Feature</h6>

                    <!-- Title -->
                    <h1 class="mb-2">
                        Request a new feature
                    </h1>

                    <hr/>
                </div>
            </div> <!-- / .row -->
            
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-10 col-xl-10">
                <h4>Feature description</h4>
                <h6 class="text-muted">Please be as descriptive as possible and let us know how this will help your business.</h6>

                <p class="mb-3">
                  <validation-observer ref="featureForm">
                    <b-form
                      class="auth-register-form mt-2"
                      @submit.prevent="sumbitForm"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="feature description"
                          rules="required"
                        >
                          <b-form-textarea
                            id="featureDescription"
                            v-model="featureDescription"
                            rows="4"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-form>
                  </validation-observer>
                </p>
                <div>
                  <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.4"
                    spinner-small
                    spinner-variant="primary"
                  >
                    <b-button
                      v-on:click="sumbitForm()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
                      class="mt-2 mb-2"
                    >
                      Request Feature
                    </b-button>
                  </b-overlay>
                </div>
              </div>
            </div> 
        </div>
    </div>
</template>

<script>
import {
  BTab, 
  BTabs, 
  VBHover, 
  BForm, 
  BButton, 
  BFormTextarea, 
  BFormInput, 
  BFormGroup, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BMedia,
  BMediaAside,
  BMediaBody, 
  BLink,
  BFormFile,
  BCard,
  BCardText,
  BFormInvalidFeedback,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { getFirestore, addDoc, collection, serverTimestamp} from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const db = getFirestore()
const auth = getAuth()

export default {
  components: {
    BTab,
    BTabs,
    VBHover,
    BForm,
    BButton, 
    BFormTextarea,
    BFormInput, 
    BFormGroup, 
    BInputGroup, 
    BInputGroupAppend,
    BImg,
    BMediaAside,
    BMediaBody, 
    BMedia,
    BLink,
    BFormFile,
    BCardText,
    BCard,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    required,
  },
  directives: {
    Ripple,
    'b-hover': VBHover
  },
  data() {
    return {
      featureDescription: null,
      busy: false,
    }
  },
  mounted() {
    this.username = (auth && auth.currentUser)?auth.currentUser.displayName:null
  },
  methods: {
    async sumbitForm() {
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.featureForm.validate()
      if (!validateForm) return

      this.busy = true
      await addDoc(collection(db, "features"), {username:this.username, feature:this.featureDescription, created: serverTimestamp()})
      this.busy = false
      this.featureDescription = ''
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Feature Request Submitted`,
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },timeout: 5000
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

